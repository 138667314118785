@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.bundle.min.js);
@import url(https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.0.3/css/font-awesome.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  /* background-color: #fff; */
}

.startButton {
  border: none;
  background-color: #fff;
}

.App-logo {
  height: 40vh;
  pointer-events: none;
}

.Start {
  align-self: center;
  height: 6vh;
  margin-top: 10;
}

.Title {
  color: #29A7DF;
  font-size: 35px;
}

.topContainer {
  padding: 10;
}

.level {
  align-self: center;
  height: 5vh;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.inst {
  align-self: initial;
  margin-left: 20%;
  /* margin-top: 5%; */
}

.levelContainer {
  /* width: 10vh; */
  /* flex-direction: row;
  vertical-align: middle; */
  display: inline-block;
}

.instructionContainer {
  margin-top: 10px;
  display: inline-block;
}

.levelText {
  display: inline-block;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.leftText {
  align-self: start;
  text-align: start;

}

.App-header {
  background-color: #282c34;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
